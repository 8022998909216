import plugIcon from '../../img/icons/plugIcon.png';
import '../../sass/fetcherror.css';

const FetchError = () => {

    return(
        <section className="fetchError-wrapper">
            <div className="fetchError-img-wrapper">
                <img className="fetchError-img" src={plugIcon} alt="ikona przedstawiająca odpiętą wtyczkę od kontaktu"/>
            </div>
            <p className="fetchError-text">Coś poszło nie tak :(</p>
            <p className="fetchError-text">Próbowałeś może włączyć i wyłączyć?</p>
        </section>
    );
};

export default FetchError;