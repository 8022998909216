import {ThemeContext} from "../components/context/ThemeContext";
import {useContext} from "react";
import LineChartWidget from "./LineChartWidget";
import LineChartWidgetDark from "./LineChartWidgetDark";

const LineChartWrapper = ({publicationStockSymbol}) => {


    const {theme} = useContext(ThemeContext);

    return(
        <>
            {theme === 'light' && (
                <LineChartWidget symbols={publicationStockSymbol}/>
            )}
            {theme === 'dark' && (
                <LineChartWidgetDark symbols={publicationStockSymbol}/>
            )}
        </>
    );
};

export default LineChartWrapper
