import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link, useNavigate, useLocation} from 'react-router-dom';
import '../../sass/sideMenu.css';
import veritaLogoFullBlack from '../../img/logo/veritaLogo-Full-Black.svg';
import veritaLogoFullWhite from '../../img/logo/veritaLogo-Full-White.svg';
// import veritaSygnet from '../../img/logo/veritaSygnet.svg';
import arrow from '../../img/icons/greenArrow.svg';

import categoryWszystkie from '../../img/icons/category/wszystkie.svg';
import categoryWydarzenia from '../../img/icons/category/wydarzenia.svg';
import categoryPolityka from '../../img/icons/category/polityka.svg';
import categorySport from '../../img/icons/category/sport.svg';
import categoryBiznes from '../../img/icons/category/biznes.svg';
import {ThemeContext} from "../context/ThemeContext";

function SideMenu({
                      onSelectCategory,
                      currentCategory,
                      currentSortOption,
                      onSelectSortOption,
                      sources,
                      onSelectSource,
                      selectedSource,
                      isExpanded,
                      toggleMenu
                  }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeCategory, setActiveCategory] = useState(currentCategory);
    const [showArrow, setShowArrow] = useState(false);
    const sourceListRef = useRef(null);

    const {theme, toggleTheme} = useContext(ThemeContext);

    useEffect(() => {
        if (currentCategory !== '' && currentSortOption === 'popular') {
            setActiveCategory('');
            onSelectCategory('');
        } else {
            setActiveCategory(currentCategory);
        }
    }, [currentCategory, currentSortOption, onSelectCategory]);

    useEffect(() => {
        const checkIfScrollable = () => {
            if (sourceListRef.current && sourceListRef.current.scrollHeight > sourceListRef.current.clientHeight) {
                setShowArrow(true);
            } else {
                setShowArrow(false);
            }
        };

        checkIfScrollable();
        window.addEventListener('resize', checkIfScrollable);

        return () => {
            window.removeEventListener('resize', checkIfScrollable);
        };
    }, [sources]);

    const handleCategoryClick = (category) => {
        setActiveCategory(category);
        onSelectCategory(category);
        toggleMenu(false);
        if (currentSortOption !== 'latest') {
            onSelectSortOption('latest');
        }
        if (location.pathname.includes('/post/') || location.pathname.includes('/rodo') || location.pathname.includes('/regulamin-serwisu') || location.pathname.includes('/polityka-prywatnosci')) {
            navigate('/');
        }
    };

    const handleLogoClick = () => {
        setActiveCategory('');
        onSelectCategory('');
        onSelectSource('');
        onSelectSortOption('latest');
        navigate("/");
        toggleMenu(false);
    };

    const handleSourceClick = (sourceName) => {
        onSelectSource(sourceName);
        if (!location.pathname.includes('/sources')) {
            navigate(`/post/${sourceName}/sources`);
        }
        toggleMenu(false);
    };

    return (
        <>
            {isExpanded && (
                <div className="overlay" onClick={() => toggleMenu(false)}></div>
            )}
            <aside className={`sideMenuContainer ${isExpanded ? 'expanded' : ''}`}>
                <div className="sideMenuFirstWrapper">
                    <div className="sideMenuFirstWrapper-logoWrapper" onClick={handleLogoClick}>
                        {theme === 'light' ? (
                            <img src={veritaLogoFullBlack} alt="Verita News company logo"/>
                        ) : (
                            <img src={veritaLogoFullWhite} alt="Verita News company logo"/>
                        )}
                        {/*<img className="signet" src={veritaSygnet} alt="Verita News company logo"/>*/}
                    </div>
                    <div className="buttonWrapper">
                        {location.pathname.includes('/sources') ? (
                            <>
                                <button
                                    onClick={() => handleSourceClick(null)}
                                    className={!selectedSource ? 'active' : ''}
                                >
                                    <p>Wszystkie</p>
                                </button>
                                <div className="sourceList" ref={sourceListRef}>
                                    {sources && sources.length > 0 ? (
                                        sources.map((source) => (
                                            <button
                                                key={source.sourceId}
                                                onClick={() => handleSourceClick(source.sourceName)}
                                                className={`buttonWrapper-sourceButton ${selectedSource === source.sourceName ? 'active' : ''}`}
                                            >
                                                <p className="buttonWrapper-sourceName">{source.sourceName}</p>
                                            </button>
                                        ))
                                    ) : (
                                        <p>Brak źródeł do wyświetlenia.</p>
                                    )}
                                </div>
                                {showArrow && (
                                    <div className="scrollIndicator">
                                        <img src={arrow} alt="Scroll down"/>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <button
                                    onClick={() => handleCategoryClick('')}
                                    className={activeCategory === '' && currentSortOption !== 'popular' ? 'active' : ''}
                                >
                                    <img className="categoryImg" src={categoryWszystkie}
                                         alt="Every news category icon"/>
                                    <p>Wszystkie</p>
                                </button>
                                <button
                                    onClick={() => handleCategoryClick('news')}
                                    className={activeCategory === 'news' && currentSortOption !== 'popular' ? 'active' : ''}
                                >
                                    <img className="categoryImg" src={categoryWydarzenia} alt="News category icon"/>
                                    <p>Wydarzenia</p>
                                </button>
                                <button
                                    onClick={() => handleCategoryClick('sport')}
                                    className={activeCategory === 'sport' && currentSortOption !== 'popular' ? 'active' : ''}
                                >
                                    <img className="categoryImg" src={categorySport} alt="Sport category icon"/>
                                    <p>Sport</p>
                                </button>
                                <button
                                    onClick={() => handleCategoryClick('politics')}
                                    className={activeCategory === 'politics' && currentSortOption !== 'popular' ? 'active' : ''}
                                >
                                    <img className="categoryImg" src={categoryPolityka} alt="Politics category icon"/>
                                    <p>Polityka</p>
                                </button>
                                <button
                                    onClick={() => handleCategoryClick('business')}
                                    className={activeCategory === 'businessandfinance' && currentSortOption !== 'popular' ? 'active' : ''}
                                >
                                    <img className="categoryImg" src={categoryBiznes} alt="Business category icon"/>
                                    <p>Biznes</p>
                                </button>
                            </>
                        )}
                    </div>
                </div>
                <div className="sideMenuSecondWrapper">
                    <button className="sideMenuSecondWrapper-themeButton" onClick={toggleTheme}>
                        {theme === 'light' ? 'Tryb ciemny' : 'Tryb jasny'}
                    </button>
                    <Link to="/polityka-prywatnosci" onClick={() => toggleMenu(false)}>Polityka prywatności</Link>
                    <Link to="regulamin-serwisu" onClick={() => toggleMenu(false)}>Regulamin serwisu</Link>
                    <Link to="/rodo" onClick={() => toggleMenu(false)}>RODO</Link>
                </div>
            </aside>
        </>
    );
}

export default SideMenu;
