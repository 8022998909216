import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CapacitorHttp } from '@capacitor/core';
import '../sass/articleSources.css';
import Loader from "../components/technicalComponents/Loader";
import placeHolder from "../img/veritaPlaceHolder.webp";

const ArticleSources = ({ setSources, selectedSource }) => {
    const { id } = useParams();
    const [article, setArticle] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchArticle = async () => {
            const options = {
                url: `https://cms.verita.news/v1/post/news/${id}`,
            };

            try {
                const response = await CapacitorHttp.get(options);
                const allSources = response.data.sourceInfo.sources;

                const uniqueSources = allSources.filter(
                    (source, index, self) =>
                        index === self.findIndex((s) => s.sourceName === source.sourceName)
                );

                setSources(uniqueSources);

                setArticle({
                    ...response.data,
                    sourceInfo: {
                        sources: selectedSource
                            ? allSources.filter(
                                (source) => source.sourceName === selectedSource
                            )
                            : allSources,
                    },
                });
            } catch (error) {
                console.error('Error fetching article:', error);
            }
        };

        fetchArticle();
    }, [id, setSources, selectedSource]);

    if (!article) {
        return <Loader/>;
    }

    return (
        <section className="articleSources-container">
            <div className="articleSources-list">
                {article.sourceInfo.sources.map((source) => {
                    const formattedSourceName = source.sourceName
                        .toLowerCase()
                        .replace(/ /g, '');
                    const sourceImage = `https://storage.googleapis.com/image.verita.news/sources/${formattedSourceName}.png`;
                    return (
                    <article className="articleSources-item" key={source.sourceId}>
                        <a href={source.redirectLink} target="_blank" rel="noopener noreferrer">
                            <div className="articleSources-item-info">
                                <div className="articleSources-item-info-companyWrapper">
                                    <img className="companyLogoSources" src={sourceImage} alt={`${source.sourceName} logo`} onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = "https://storage.googleapis.com/image.verita.news/sources/notFound.png";
                                    }}/>
                                    <p className="articleSources-item-info__sourceName">{source.sourceName}</p>
                                </div>
                                <h4 className="articleSources-item-info__title">{source.title}</h4>
                            </div>
                            <img className="articleSources-item-image" src={source.sourceImageUrl} alt={source.sourceName} onError={(e ) => {
                                e.target.onerror = null;
                                e.target.src = {placeHolder}
                            }} />
                        </a>
                    </article>
                    );
                })}
            </div>
        </section>
    );
};

export default ArticleSources;
