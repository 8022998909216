import Timer from "../technicalComponents/Timer";
import Category from "../technicalComponents/Category";
import {useNavigate} from "react-router-dom";
import newsPaperIcon from '../../img/icons/newsPaperIcon.svg';
import newsPaperIconDark from '../../img/icons/newsPaperIconDark.svg';
import arrowIcon from '../../img/icons/greenArrow.svg';
import '../../sass/articleImageVersion.css';
import {useContext, useEffect, useState} from "react";

import {ThemeContext} from "../context/ThemeContext";
import placeHolder from "../../img/veritaPlaceHolder.webp";


const ArticleImageVersion = ({post}) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);
    const {theme} = useContext(ThemeContext);
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate(`/post/${post.itemId}`);
    };

    const handleViewAllSources = (event) => {
        event.stopPropagation();
        navigate(`/post/${post.itemId}/sources`);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 800);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <article className="article-container">
            <div className="articleContent" onClick={handleRedirect}>
                <div className="articleContent-mainInfo">
                    <Category category={post.category}></Category>
                    <h2>{post.title}</h2>
                    <Timer creationDate={post.creationDate} customStyle={!isMobile ? "withMargin" : ""} />
                    <p className="articleContent-text">Piszą o tym:</p>
                </div>
                <div className="articleContent-grid">
                    <div className="articleContent-grid-mainSource">
                        {post.sourceInfo.sources[0] && (() => {
                                const formattedSourceName = post.sourceInfo.sources[0].sourceName
                                    .toLowerCase()
                                    .replace(/ /g, '');
                                const sourceImage = `https://storage.googleapis.com/image.verita.news/sources/${formattedSourceName}.png`;
                                return (
                                    <a href={post.sourceInfo.sources[0].redirectLink} target="_blank"
                                       rel="noopener noreferrer">
                                        <div className="articleContent-imageWrapper">
                                            <img className="articleContent-grid-mainSource-image" src={post.sourceInfo.sources[0].sourceImageUrl}
                                                 alt={post.sourceInfo.sources[0].sourceName} onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src = {placeHolder}
                                            }}/>
                                        </div>
                                        <div className="articleContent-grid-mainSource-companyWrapper">
                                            <img className="companyLogo" src={sourceImage} alt={`${sourceImage} logo`} onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src="https://storage.googleapis.com/image.verita.news/sources/notFound.png";
                                            }}/>
                                            <p>{post.sourceInfo.sources[0].sourceName}</p>
                                        </div>
                                        <h4>{post.sourceInfo.sources[0].title}</h4>
                                    </a>
                                );
                        })()}
                    </div>
                    <div className="articleContent-grid-otherSources">
                        {post.sourceInfo.sources.slice(1, 4).map((source) => {
                            const formattedSourceName = source.sourceName
                                .toLowerCase()
                                .replace(/ /g, '');
                            const sourceImage = `https://storage.googleapis.com/image.verita.news/sources/${formattedSourceName}.png`;

                            return (
                                <div className="articleContent-grid-sources-element" key={source.sourceId}>
                                    <a href={source.redirectLink} target="_blank" rel="noopener noreferrer">
                                        <div className="articleContent-imageWrapper">
                                            <img className="articleContent-grid-sources-element-imgOnlyMobile" src={source.sourceImageUrl} alt={source.sourceName} onError={(e ) => {
                                                e.target.onerror = null;
                                                e.target.src = {placeHolder}
                                            }}/>
                                        </div>
                                        <div className="articleContent-grid-sources-element-companyWrapper">
                                            <img className="companyLogo" src={sourceImage} alt={`${source.sourceName} logo`} onError={(e) => {
                                                e.target.onerror = null;
                                                e.target.src="https://storage.googleapis.com/image.verita.news/sources/notFound.png";
                                            }} />
                                            <p className="articleContent-grid-sources-element__sourceName">{source.sourceName}</p>
                                        </div>
                                        <h4 className="articleContent-grid-sources-element__title">{source.title}</h4>
                                    </a>
                                </div>
                            );
                        })}
                        {isMobile && (
                            <div className="articleContent-grid-sources-element-showAllBig" key="show all" onClick={handleViewAllSources}>
                                <div className="articleContent-sources-element-banner-wrapper">
                                    <div className="articleContent-sources-element-banner">
                                        <p>Zobacz wszystkie materiały</p>
                                        <img src={arrowIcon} alt="arrow icon"/>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="articleContent-grid-otherSources-showAll" onClick={handleViewAllSources}>
                            <div className="articleContent-grid-otherSources-showAll-textAndIcon">
                                <img src={theme === 'light' ? newsPaperIcon : newsPaperIconDark} alt="newsPaperIcon"/>
                                <p>Zobacz wszystkie materiały</p>
                            </div>
                            <img className="arrowIconAllSources" src={arrowIcon} alt="arrow icon suggesting that user can click it"/>
                        </div>
                    </div>
                </div>
                <div className="articleContent-summaryImage">
                    <span className="summaryImage-bigText">W skrócie</span>
                    <div className="summaryImage-pointLine">
                        <div className="summaryImage-pointLine-dot"></div>
                        <div className="summaryImage-pointLine-text-wrapper">
                            <p className="blurry-text" data-text="{post.summary[0]}">{post.summary[0]}</p>
                        </div>
                    </div>
                    <div className="summaryImage-buttonWrapper">
                        <p className="summaryImage-buttonWrapper-text">Przeczytaj więcej</p>
                        <img className="summaryImage-buttonWrapper-img" src={arrowIcon} alt="arrow icon"/>
                    </div>
                </div>
            </div>
        </article>
    );

};

export default ArticleImageVersion;