import React, {useEffect, useRef, useState, useCallback, useContext} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import greenBigArrow from '../../img/icons/greenBigArrow.svg';
import greenArrow from '../../img/icons/greenArrow.svg';
import logo from '../../img/logo/veritaLogo-Full-Black.svg';
import logoDark from '../../img/logo/veritaLogo-Full-White.svg';
import '../../sass/navbar.css';
import {ThemeContext} from "../context/ThemeContext";

function Navbar({onSelectSortOption, currentSortOption, onToggleSideMenuExpand, currentCategory, selectedSource, onSelectSource}) {
    const lineRef = useRef(null);
    const latestButtonRef = useRef(null);
    const popularButtonRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();

    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [navHeight, setNavHeight] = useState(85);
    const [navHidden, setNavHidden] = useState(false);
    const [mobileNavHidden, setMobileNavHidden] = useState(false);

    const {theme} = useContext(ThemeContext);
    const setLinePosition = useCallback(() => {
        if (currentSortOption === 'latest' && latestButtonRef.current) {
            lineRef.current.style.left = `${latestButtonRef.current.offsetLeft}px`;
            lineRef.current.style.width = `${latestButtonRef.current.offsetWidth}px`;
        } else if (currentSortOption === 'popular' && popularButtonRef.current) {
            lineRef.current.style.left = `${popularButtonRef.current.offsetLeft}px`;
            lineRef.current.style.width = `${popularButtonRef.current.offsetWidth}px`;
        }
    }, [currentSortOption]);

    useEffect(() => {
        setLinePosition();
    }, [currentSortOption, setLinePosition]);

    useEffect(() => {
        if (!location.pathname.includes('/post/')) {
            setLinePosition();
        }
    }, [location, setLinePosition]);


    useEffect(() => {
        if (!location.pathname.includes('/sources') && !location.pathname.includes('/post')) {
            onSelectSource(null);
        }
    }, [location, onSelectSource]);



    useEffect(() => {
        const isHomePage = location.pathname === '/';

        const isMobile = window.innerWidth <= 800;

        setNavHeight(isHomePage && isMobile ? 100 : 85);
    }, [location.pathname]);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            const difference = currentScrollPos - prevScrollPos;

            const isHomePage = location.pathname === '/';
            const isMobile = window.innerWidth <= 800;

            if (currentScrollPos === 0) {
                setNavHidden(false);
                setNavHeight(isHomePage && isMobile ? 100 : 85);
                if (isMobile) {
                    setMobileNavHidden(false);
                }
            } else if (difference > 20 && !navHidden) {
                setNavHidden(true);
                setNavHeight(isHomePage && isMobile ? 47 : 47);
                if (isMobile) {
                    setMobileNavHidden(true);
                }
            } else if (difference < -20 && navHidden) {
                setNavHidden(false);
                setNavHeight(isHomePage && isMobile ? 100 : 85);
                if (isMobile) {
                    setMobileNavHidden(false);
                }
            }

            setPrevScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos, navHidden, location.pathname]);

    useEffect(() => {
        const handleResize = () => {
            setLinePosition();
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [setLinePosition]);

    const isArticlePage = location.pathname.includes('/post/') && !location.pathname.includes('/sources');
    const isSourcePage = location.pathname.includes('/sources');
    const isRodoPage = location.pathname.includes('/rodo');
    const isPrivacyPolicyPage = location.pathname.includes('/polityka-prywatnosci');
    const isWebsiteRegulationsPage = location.pathname.includes('/regulamin-serwisu');

    const handleBackClick = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };

    const categoryTranslations = {
        news: 'Wydarzenia',
        politics: 'Polityka',
        sport: 'Sport',
        business: 'Biznes'
    };

    const translatedCategory = categoryTranslations[currentCategory] || currentCategory;

    return (
        <nav>
            <div className="nav-wrapper" style={{height: `${Math.round(navHeight)}px`, transition: 'height 0.3s'}}>
                {isArticlePage ? (
                    <div className="nav-postAndSourcePage">
                        <div className="nav-postAndSourcePage-wrapper" onClick={handleBackClick}>
                            <img src={greenBigArrow} alt="arrow suggesting that user can go back"/>
                            <p>Wróć</p>
                        </div>
                        <h3 className="nav-postAndSourcePage-singleText">Artykuł</h3>
                    </div>
                ) : isSourcePage ? (
                    <div className="nav-postAndSourcePage">
                        <div className="nav-postAndSourcePage-wrapper" onClick={handleBackClick}>
                            <img src={greenBigArrow} alt="arrow suggesting that user can go back"/>
                            <p>Wróć</p>
                        </div>
                        <h3 className="nav-postAndSourcePage-singleText">Wszystkie artykuły</h3>
                    </div>
                ) : isRodoPage ? (
                    <div className="nav-postAndSourcePage">
                        <div className="nav-postAndSourcePage-wrapper" onClick={handleBackClick}>
                            <img src={greenBigArrow} alt="arrow suggesting that user can go back"/>
                            <p>Wróć</p>
                        </div>
                        <h3 className="nav-postAndSourcePage-singleText">RODO</h3>
                    </div>
                ) : isPrivacyPolicyPage ? (
                    <div className="nav-postAndSourcePage">
                        <div className="nav-postAndSourcePage-wrapper" onClick={handleBackClick}>
                            <img src={greenBigArrow} alt="arrow suggesting that user can go back"/>
                            <p>Wróć</p>
                        </div>
                        <h3 className="nav-postAndSourcePage-singleText">Polityka prywatności</h3>
                    </div>
                ) : isWebsiteRegulationsPage ? (
                    <div className="nav-postAndSourcePage">
                        <div className="nav-postAndSourcePage-wrapper" onClick={handleBackClick}>
                            <img src={greenBigArrow} alt="arrow suggesting that user can go back"/>
                            <p>Wróć</p>
                        </div>
                        <h3 className="nav-postAndSourcePage-singleText">Regulamin serwisu</h3>
                    </div>
                ) : (
                    <div className="logoWrapperSecond">
                        <div className="logoWrapper">
                            {theme === 'light' && (
                                <img src={logo} alt="verita news logo"/>
                            )}
                            {theme === 'dark' && (
                                <img src={logoDark} alt="verita news logo"/>
                            )}
                        </div>
                        <div className="buttonWrapperSecond">
                            <button
                                ref={latestButtonRef}
                                className={`nav-button ${currentSortOption === 'latest' ? 'activeSort' : ''}`}
                                onClick={() => onSelectSortOption('latest')}
                            >
                                <h3 className="navButton-text">Najnowsze</h3>
                            </button>
                            <button
                                ref={popularButtonRef}
                                className={`nav-button ${currentSortOption === 'popular' ? 'activeSort' : ''}`}
                                onClick={() => onSelectSortOption('popular')}
                            >
                                <h3 className="navButton-text">Popularne</h3>
                            </button>
                            <div className="underline" ref={lineRef}></div>
                        </div>
                    </div>
                )}
            </div>
            {isArticlePage && <div style={{ height: '1px' }}></div>}
            {!isArticlePage && (
                <div
                    className="mobileCategoryNav-Wrapper"
                    onClick={onToggleSideMenuExpand}
                    style={{
                        transform: mobileNavHidden ? 'translateY(-100%)' : 'translateY(0)',
                        opacity: mobileNavHidden ? 0 : 1,
                        // transition: 'transform 0.5s ease,'
                        transition: 'transform 0.5s ease, opacity 0.5s ease'
                    }}
                >
                    <div className="nav-button mobile-category-button">
                        <div className="category">

                            <div className="category">
                                {isSourcePage && !selectedSource ? (
                                    <>
                                        <span className="category-label">Wszystkie</span>
                                        <span className="category-value">źródła</span>
                                    </>
                                ) : selectedSource ? (
                                    <>
                                        <span className="category-label">Źródło</span>
                                        <span className="category-value">{selectedSource}</span>
                                    </>
                                ) : currentCategory ? (
                                    <>
                                        <span className="category-label">Kategoria</span>
                                        <span className="category-value">{translatedCategory}</span>
                                    </>
                                ) : (
                                    <>
                                        <span className="category-label">Wszystkie</span>
                                        <span className="category-value">kategorie</span>
                                    </>
                                )}
                            </div>

                        </div>
                        <img src={greenArrow} alt="arrow icon"/>
                    </div>
                </div>
            )}
        </nav>
    );
}

export default Navbar;
