import React from 'react';
import ArticleImageVersion from "./ArticleImageVersion";
import ArticleWithoutImageVersion from "./ArticleWithoutImageVersion";
import '../../sass/articleList.css';


function ArticleList({ articles, sortOption }) {
    return (
        <section className="articleList">
            {articles.map((post, index) => (
                post.fullPost ? (
                    <ArticleImageVersion key={`${sortOption}-${post.itemId}-${index}`} post={post} />
                ) : (
                    <ArticleWithoutImageVersion key={`${sortOption}-${post.itemId}-${index}`} post={post} />
                )
            ))}
        </section>
    );
}

export default ArticleList;
